#servicos{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 130px;
}

#servicos-circuit{
    width: 40%;
    position: fixed;
    left: 0;
    top: 50px;
    z-index: -1;
}

.part-servicos{
    width: 85%;
}

.part-servicos h2{
    color: var(--white-1) !important;
    background: none;
    -webkit-text-fill-color: unset;
}

.part-servicos h2::after {
    content: '';
    width: 100%;
    margin: -10px 0 25px 0;
    background: linear-gradient(270deg, #bf2147, #1462a6);
    background-size: 400% 400%;
    animation: GlowGradient 5s ease infinite;
    height: 2px;
    display: block;
}

.part-servicos h2 span{
    color: var(--code-blue);
    font-size: 100px;
    line-height: 1px;
}

.part-servicos details{
    background-color: var(--black-1);
    margin: 35px 0 35px 0;
    border-radius: 25px;
    
}

.icon-servicos{
    fill: var(--code-blue);
}

.icon-servicos-wrapper{
    height: 50px;
    margin: 0 35px 0 0;
}

.part-servicos details summary{
    padding: 35px 25px 35px 25px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

details .content{
    background-color: var(--code-red);
    padding: 25px;
    border-radius: 25px;
}

details[open] .icon-servicos{
    fill: #bf2147;
}

#pt-4::before {
    content: '';
    width: 100%;
    margin: 100px 0 100px 0;
    background: linear-gradient(270deg, #bf2147, #1462a6);
    background-size: 400% 400%;
    -webkit-animation: GlowGradient 5s ease infinite;
    -moz-animation: GlowGradient 5s ease infinite;
    animation: GlowGradient 5s ease infinite;
    height: 2px;
    display: block;
}