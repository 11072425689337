.part-home {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
    overflow: hidden;
}

.super-content {
    position: absolute;
    max-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

#hrrounded {
    width: 100%;
}

#banner1 {
    width: 100%;
    height: calc(100% + 50px);
    object-fit: cover;
    object-position: center;
}

#wrapper-banner1 {
    width: 100%;
    height: 92vh;
    overflow: hidden;
    object-fit: cover;
    object-position: center;
}

#fav-icon {
    bottom: 20px;
    position: absolute;
    width: 40px;
}

#content-princ {
    margin-left: 50px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#content-princ svg {
    height: 100vh;
    width: 80%;
    mix-blend-mode: color-dodge;
}

#paths{
    fill: #1462a6;
}

#content-princ svg g, path{
    transition: none !important;
}

#text-princ {
    position: absolute;
    z-index: 2;
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

#content-sec {
    position: absolute;
    right: 0;
    background-color: #22303a86;
    backdrop-filter: blur(1px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: none !important;
    border: 1px solid var(--code-blue);
    border-radius: 25px 0 0 25px;
}

#cont-sec-1,
#cont-sec-2 {
    display: flex;
    flex-direction: column;
    align-items: center;

}

#cont-sec-2 {
    padding: 20px;
}

#cont-sec-1 {
    border-bottom: 1px solid var(--code-blue);
    border-radius: 25px;
    padding: 20px;
}

#cont-sec-1 h2,
#cont-sec-2 h2 {
    color: var(--code-red);
    background: unset;
    background-clip: unset;
    -webkit-text-fill-color: unset;
}


.tickerContainer{
    display: none;
}


@media (max-width: 1200px) {
    .super-content {
        height: auto;
        position: absolute;
        display: flex;
        flex-direction: column;
    }

    #content-princ {
        margin: 60px 0 60px 0;
        flex-direction: column;
        justify-content: center;
    }

    #text-princ {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    #content-princ svg {
        margin: 0;
        width: 90vw;
    }

    #content-sec {
        display: none;
    }

    .tickerContainer{
        display: block;
    }
}

/* LETTERING */

.ticker {
    height: 50px;
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 1300px;
    background-color: var(--black-3);
    border: solid 1px var(--code-red);
    color: var(--white-3);
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
}

.ticker div {
    width: 200%;
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    overflow: hidden;
    animation: ticker 10s linear infinite;
}

.ticker span {
    float: left;
    width: 50%;
}

@keyframes ticker {
    0% {
        left: 0;
    }

    100% {
        left: -100%;
    }
}

/* PARTE 2 */

.pt-2::after {
    content: '';
    width: 100%;
    margin: -10px 0 25px 0;
    background: linear-gradient(270deg, #bf2147, #1462a6);
    background-size: 400% 400%;
    animation: GlowGradient 5s ease infinite;
    height: 2px;
    display: block;
}

#pt-2 {
    width: 90%;
}

#banner2 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

#wrapper-banner2 {
    width: 100%;
    height: 110vh;
    overflow: hidden;
    object-fit: cover;
    object-position: center;
}

#jobs-pt2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 50px;
}

#jobs-pt2 h2::after {
    content: '';
    width: 80%;
    margin: -10px 0 25px 0;
    background: linear-gradient(270deg, #bf2147, #1462a6);
    background-size: 400% 400%;
    -webkit-animation: GlowGradient 5s ease infinite;
    -moz-animation: GlowGradient 5s ease infinite;
    animation: GlowGradient 5s ease infinite;
    height: 2px;
    display: block;
}

#jobs-pt2 h3 {
    margin-bottom: 10px;
}

#jobs-pt2 ul {
    list-style-position: inside !important;
    margin: 0;
    padding: 0;
}

@media (max-width: 1200px) {
    #pt-2 {
        width: 100%;
    }

    #banner2 {
        object-position: left;
    }

    #text-pt2 {
        margin-bottom: 50px;
    }

    #text-pt2 h1,
    #text-pt2 h3 {
        text-align: center;
    }
}

/* PARTE 3 */

.banner3 {
/* The image used */
background-image: url("img/banner3-min.webp"); /* Adapte o caminho conforme necessário */

/* Set a specific height */
height: 80vh; 
width: 100vw;

/* Create the parallax scrolling effect */
background-attachment: fixed;
background-position: center;
background-repeat: no-repeat;
background-size: cover;

border-radius: 50px;
border: solid 1px var(--code-red);
}

#text-pt3{
    text-align: center;
    margin-bottom: 50px;
}

#wrapper-pt3{
    display: flex;
    align-items: center;
    justify-content: center;
}

#pt-3 div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 40px;
    padding: 25px 10px 25px 10px;
    border-radius: 25px;
    background-color: #1462a66e;
    border: var(--code-red) 2px solid;
    backdrop-filter: blur(5px);
    min-height: 350px;
    width: 25%;
}

.icons-hab-pt3{
    height: 50px;
    margin-bottom: 25px;
}

@media (max-width: 1200px) {
    #pt-3{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(4, auto);
        justify-items: center;
        margin-bottom: 50px;
        max-height: 500px;
        width: 90%;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    #pt-3 div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 25px 0 25px 0;
        padding: 0;
        min-height: 50vh;
        width: 80%;
    }
}

/* PARTE 4 */

#text-pt4{
    margin: 25px;
    text-align: center;
    line-height: 20px;
}

#text-pt4 div{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

#text-pt4 p span{
    color: var(--code-blue);
}

.btn-simple{
    margin: 50px 0 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 60px;
    min-width: 200px;
    padding: 0 15px 0 15px;
    max-width: 400px;
    border-radius: 30px;
    position: relative !important;
    color: var(--white-1);
    background: var(--gradient-basic-hor);
    background-size: 100%;
    cursor: pointer;
    border: solid 1px var(--white-1);
}

.btn-simple:hover{
    animation: glow-btn-animate 8s linear infinite;
    background: transparent;
    border: solid 1px var(--white-1);
}


.btn-simple:before{
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    bottom: -5px;
    right: -5px;
    z-index: -1;
    background: linear-gradient(90deg,#2182BF,#BF2147,#c7b617,#2182BF);
    background-size: 400%;
    border-radius: 40px;
    opacity: 0;
    transition: 1s;
}

.btn-simple:hover:before{
    filter: blur(10px);
    opacity: 1;
    animation: glow-btn-animate 8s linear infinite;
}

.btn-simple h3{
    font-size: 20px;
}


@media screen and (max-width: 600px) {
    .btn-simple,
    .btn-simple h3{
        font-size: 4vw;
        min-width: 100px;
    }
}