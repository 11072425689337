#tmp-espaco{
    height: 100vh;
}

footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: 0;
    background-color: var(--black-3);
}

footer::before{
    content: '';
    width: 100%;
    background: linear-gradient(270deg, #bf2147, #1462a6);
    background-size: 400% 400%;
    -webkit-animation: GlowGradient 5s ease infinite;
    -moz-animation: GlowGradient 5s ease infinite;
    animation: GlowGradient 5s ease infinite;
    height: 2px;
    position: absolute;
}

#footer-min{
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-evenly;
}

#footer-min p3{
    margin: 10px 0 10px 0;
}

.footerlink{
    color: var(--white);
}

.footerlink:hover{
    color:#1462a6;
}

#footer-min::before{
    content: '';
    width: 100%;
    background: linear-gradient(270deg, #bf2147, #1462a6);
    background-size: 400% 400%;
    -webkit-animation: GlowGradient 5s ease infinite;
    -moz-animation: GlowGradient 5s ease infinite;
    animation: GlowGradient 5s ease infinite;
    height: 2px;
    position: absolute;
}

#footer-master{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 85%;
}

#footer-social{
    margin: 25px 0 25px 0;
}

#footer-social div{
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
}

#logo-footer{
    width: 150px;
}

.icon-social{
    width: 35px;
}

#footer-menu{
    display: flex;
    gap: 100px;
}

#footer-menu ul{
    padding: 20px 0 0 0;
    
}

#footer-menu ul li{
    list-style-type: decimal;
    list-style-position: inside;
    line-height: 28px;
}

#footer-menu h3 span{
    color: var(--code-blue);
    font-weight: 900;
}

@media (max-width: 1000px) {
    #footer-min{
        flex-direction: column;
        text-align: center;
    }

    #footer-min::before{
        position: relative;
    }

    #footer-master{
        flex-direction: column;
    }

    #footer-menu{
        margin: 0 0 50px 0;
        flex-direction: column;
        align-items: flex-start;
    }

    #footer-social{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #logo-footer{
        width: 80%;
        margin-top: 50px;
    }

    #footer-social div{
        width: 80%;
    }

    .icon-social{
        margin: 50px 0 50px 0;
        width: 50px;
    }
}