#content-contato {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#contato {
    min-height: 100vh;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 50px;
    align-items: center;
    padding-top: 50px;
    overflow: hidden;
}

#entre-em-contato {
    margin: 10px;
    padding: 50px;
    border-radius: 25px;
    background-color: transparent;
    backdrop-filter: blur(2px);
    border: solid 2px var(--code-blue);
    align-items: center;
    display: flex;
    flex-direction: column;
}

#titulo-cont {
    color: var(--code-blue);
}

#titulo-cont span {
    color: var(--code-red);
}

#entre-em-contato p {
    width: 100%;
    margin-top: 25px !important;
    text-align: center;
}

#entre-em-contato p span {
    color: var(--code-blue);
}

#servicos-circuit {
    width: 40%;
    position: fixed;
    left: 0;
    top: 50px;
    z-index: -1;
}

#entre-em-contato .btn-simple svg {
    max-width: 50px;
}

#entre-em-contato .btn-simple svg path {
    fill: var(--white-1);
}

#custom-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 25px;
}

#custom-form .input-text {
    padding: 15px 15px 15px 15px;
    border: 1px solid white;
    background-color: var(--black-1);
    color: white;
    font-size: 16px;
    max-width: 100%;
    border-radius: 10px;
}

.input-text::placeholder {
    color: var(--white-2);
}

#custom-form input[type="submit"] {
    display: inline-block;
    border: solid 1px var(--black-1);
    cursor: pointer;
    padding: 15px 100px;
    margin: 25px 0 0 0;
    width: 100%;
    color: white;
    background-color: var(--black-1);
    font-weight: 700;
    text-decoration: none;
    border-radius: 10px;
    transition: 0.3s;
}

#custom-form input[type="submit"]:hover {
    background-color: var(--black-2);
    border: solid 1px white;
}

#msg-wrapper{
    position: absolute;
    bottom: 25px;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.msg-error {
    width: 25%;
    padding: 10px 20px 10px 20px;
    border: 1px solid white;
    border-radius: 15px;
    background-color: var(--code-red);
    color: white;
    display: none;
    position: relative;
    bottom: 0;
}

.success-message {
    padding: 10px 20px 10px 20px;
    border: 1px solid white;
    border-radius: 15px;
    background-color: var(--code-green);
    color: white;
    display: none;
    position: relative;
    bottom: 0;
}

.msg-error.active {
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.success-message.active {
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#hidden_iframe {
    width: 100%;
    height: 400px;
    display: none;
}

#wrapper-input {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}

@media screen and (max-width: 1240px) {
    #contato {
        display: grid;
        grid-template-columns: 1fr;
        /* Duas colunas de largura igual */
        gap: 50px;
        /* Espaçamento entre as colunas */
        align-items: center;
        padding-top: 50px;
    }

    #form-orcamento {
        padding: 0;
    }

    #entre-em-contato .btn-simple h3 {
        display: none;
    }

    #entre-em-contato .btn-simple svg {
        display: block;
    }

    #entre-em-contato .btn-simple {
        padding: 0 25px 0 25px;
    }

    #entre-em-contato {
        margin: 0;
        padding: 20px;
    }

}