header {
    width: 100vw;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #22303A94;
    backdrop-filter: blur(1px);
    position: fixed;
    z-index: 5;
}

header::after {
    content: '';
    width: 100vw;
    background: linear-gradient(270deg, #bf2147, #1462a6);
    background-size: 400% 400%;
    -webkit-animation: GlowGradient 5s ease infinite;
    -moz-animation: GlowGradient 5s ease infinite;
    animation: GlowGradient 5s ease infinite;
    height: 2px;
    display: block;
}

header div{
    width: 85%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

header img{
    height: 90px;
    width: 200px;
}

#nav-img-hor{
    display: block;
}

#nav-img-ver{
    display: none;
}

#btnMenuMobile{
    cursor: pointer;
    align-items: flex-end;
    justify-content: center;
    width: 100px;
    display: none;
}

#btnMenuMobile img{
    height: 40px;
    width: 40px;
}

#navMenu{
    justify-content: flex-end;
}

header ul{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 40%;
}

header li{
    margin-left: 20px;
    list-style-type: disc;
    list-style-position: outside;
}

header ul a li:hover {
    color: var(--code-red) !important;
}

header ul a li::marker,
.nav-link.active li::marker {
  font-size: 20px;
  color: var(--white-1);
}

.nav-link.active li::marker,
.nav-link.active li {
  color: var(--code-blue) !important;
}

.nav-link.active li:hover{
    color: var(--code-blue-dark) !important;
}

header ul a li,
header ul a li::marker {
  color: var(--white-1);
}

@media (max-width: 1000px) {

    #btnMenuMobile{
        display: contents;
    }

    #navMenu{
        display: none;
        transform: translateY(-200%);
    }

    #navMenu.active{
        display: block;
        position: absolute;
        right: 0;
        width: 100vw;
        transform: translateY(100%);
        transition: transform 1s ease;
    }

    #btnMenuMobile.active img{
        transform: rotate(90deg);
    }

    header img{
        height: 90px;
        width: 100px;
    }
    
    #nav-img-hor{
        display: none;
    }
    
    #nav-img-ver{
        display: block;
    }

    #navMenu.active ul{
        margin:0;
        padding:0;
        flex-direction: column;
    }

    #navMenu.active ul li{
        margin: 0;
        padding: 35px 0 35px 0;
        background-color: #22303ad1;
        backdrop-filter: blur(25px);
    }

    #navMenu.active ul::after {
        content: '';
        width: 100vw;
        background: linear-gradient(270deg, #bf2147, #1462a6);
        background-size: 400% 400%;
        -webkit-animation: GlowGradient 5s ease infinite;
        -moz-animation: GlowGradient 5s ease infinite;
        animation: GlowGradient 5s ease infinite;
        height: 2px;
        display: block;
    }

    #navMenu.active ul li:hover{
        background-color: #22303Abb;
    }

    header li{
        margin-left: 20px;
        list-style-type: none;
        text-align: center;
    }


}